
















































































import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { PnPTheme } from '@/interfaces/settings/theme';

@Component
export default class PundPSettings extends Vue {
  public availableThemes: PnPTheme[] = [
    {
      background: 'Background0.jpg',
      opacity: 0.5,
      colors: {
        primary: '#ce6739',
        secondary: '#52404c',
        accent: '#375782',
      },
    },
    {
      background: 'Background1.jpg',
      opacity: 0.5,
      colors: {
        primary: '#36488c',
        secondary: '#212864',
        accent: '#ad90d6',
      },
    },
    {
      background: 'Background2.jpg',
      opacity: 0.5,
      colors: {
        primary: '#cc6359',
        secondary: '#624440',
        accent: '#fbc784',
      },
    },
    {
      background: 'Background3.jpg',
      opacity: 0.5,
      colors: {
        primary: '#d1702b',
        secondary: '#5f372a',
        accent: '#7b90af',
      },
    },
    {
      background: 'Background4.jpg',
      opacity: 0.5,
      colors: {
        primary: '#ac844f',
        secondary: '#898575',
        accent: '#8c9cac',
      },
    },
    {
      background: 'Background5.jpg',
      opacity: 0.5,
      colors: {
        primary: '#8a5632',
        secondary: '#703210',
        accent: '#2f807b',
      },
    },
    {
      background: 'Background6.jpg',
      opacity: 0.5,
      colors: {
        primary: '#d57e44',
        secondary: '#64443c',
        accent: '#acac6c',
      },
    },
    {
      background: 'Background7.jpg',
      opacity: 0.5,
      colors: {
        primary: '#88a3b4',
        secondary: '#3d384e',
        accent: '#b4b45c',
      },
    },
    {
      background: 'Background8.jpg',
      opacity: 0.5,
      colors: {
        primary: '#497fa6',
        secondary: '#214057',
        accent: '#54949c',
      },
    },
    {
      background: 'Background9.jpg',
      opacity: 0.5,
      colors: {
        primary: '#c67476',
        secondary: '#6a5050',
        accent: '#fc97b8',
      },
    },
  ];
  private open: boolean = false;
  private selectedTheme: number | null = null;
  private selectedBackground: string = '';
  private selectedOpacity: number = 0;
  private originalBackground: string = '';
  private originalOpacity: number = 60;
  private originalTheme: PnPTheme | null = null;
  private accountDetails = {
    name: null,
    email: null,
    saving: false,
  };

  public created(): void {
    this.selectedBackground = this.activeBackground;
    this.selectedOpacity = this.opacity;
    this.originalBackground = this.activeBackground;
    this.originalOpacity = this.opacity;
    this.originalTheme = JSON.parse(JSON.stringify(this.$store.getters.theme));
  }

  public mounted() {
    if (!this.isLoading) {
      this.onUserDataLoad(false, true);
    }
  }

  public destroyed() {
    // Reset theme in case it was not saved
  }

  public saveAccountDetails() {
    this.accountDetails.saving = true;
    this.$store.dispatch('saveAccountDetails', this.accountDetails).then(() => {
      this.accountDetails.saving = false;
    });
  }

  public changeTheme(theme: PnPTheme, index: number) {
    this.selectedBackground = theme.background;
    this.selectedTheme = index;
    this.$store.commit('setTheme', theme);
  }

  public saveTheme() {
    if (this.selectedTheme === null) {
      throw new Error('SelectedTheme is null');
    }
    const colors = this.availableThemes[this.selectedTheme].colors;
    const payload = {
      background: this.selectedBackground,
      opacity: this.selectedOpacity,
      colors,
    };
    this.$store.dispatch('changeBackground', payload).then(() => {
      this.originalBackground = this.activeBackground;
      this.originalOpacity = this.opacity;
    });
  }

  public resetTheme() {
    this.selectedBackground = this.originalBackground;
    this.selectedOpacity = this.originalOpacity;
    this.$store.commit('setTheme', this.originalTheme);
    this.selectedTheme = null;
  }

  private getBackground(background: string) {
    return require('@/assets/css/background/' + background);
  }

  @Watch('selectedOpacity')
  @Watch('selectedBackground')
  private onOpacityChanged(newValue: number, oldValue: number) {
    // Just return to prevent overwriting on page load
    if (this.selectedTheme === null) {
      return;
    }
    const payload = {
      background: this.selectedBackground,
      opacity: this.selectedOpacity,
      colors: this.availableThemes[this.selectedTheme].colors,
    };
    this.$store.commit('setTheme', payload);
  }

  @Watch('isLoading')
  private onUserDataLoad(newValue: boolean, oldValue: boolean) {
    if (!newValue) {
      this.originalBackground = this.activeBackground;
      this.originalOpacity = this.opacity;
      this.selectedBackground = this.activeBackground;
      this.selectedOpacity = this.opacity;
      this.accountDetails.name = this.$store.getters.userData.name;
      this.accountDetails.email = this.$store.getters.getUser.email;
    }
  }

  get isLoading(): boolean {
    return this.$store.getters.userData === null;
  }

  get settings(): object {
    return this.$store.getters.settings;
  }

  get activeBackground(): string {
    return this.$store.getters.backgroundImage;
  }

  get opacity(): number {
    return this.$store.getters.backgroundOpacity;
  }

  get changedBackground(): boolean {
    return this.selectedBackground !== this.originalBackground || this.selectedOpacity !== this.originalOpacity;
  }
}
